<template>
  <v-card id="cw-confirmation-dialog--dialog__data-gathering-permission">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Reviewing of customer information
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        Tact Finance Corporation collects and processes information from other lenders on previous
        loans to process your credit application. I hereby consent to the disclosure by creditors of
        this information concerning me concerning my loans. The lender will ask the participating
        companies for this information using the technical inquiry system maintained by Suomen
        Asiakastieto Oy. The companies that submit their data to this survey system can be found at
      </p>
      <a
        id="cw-confirmation-dialog__extra-info"
        href="https://www.asiakastieto.fi/web/fi/palvelut-yrityksille/positiivinen-tieto/positiiviset-luottotiedot-kuluttajaluottojen-kyselyjarjestelmassa.html"
        target="_blank"
        @click="$eventLogger.clickEvent($event)"
      >
        https://www.asiakastieto.fi/web/fi/palvelut-yrityksille/positiivinen-tieto/positiiviset-luottotiedot-kuluttajaluottojen-kyselyjarjestelmassa.html
      </a>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogDataGatheringPermission',
};
</script>
